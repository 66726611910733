import { useEffect } from "react"
import Cookies from "universal-cookie"
import ga from "../helpers/ga";

function GoogleAnalyticsProvider({ children}) {

  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get("cookieAcceptance") === "accepted") {
      ga.allowTracking();
    }
  }, [])

  return (
    <>{ children }</>
  )
}

export default GoogleAnalyticsProvider;