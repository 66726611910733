import React, { useEffect, useState } from 'react';
/* Order matters here on these next 2 imports: App.scss should take precedence over Tailwind.css
because we don't want Tailwind stylings to overwrite our custom styles in App.scss. So we should
import Tailwind first. */
import './Tailwind.css'
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import AnimatedCursor from "react-animated-cursor"
import ScrollToTop from './components/ScrollToTop';
import Cookie from './components/Cookie';
import Routes from './Routes';
import { ParallaxProvider } from 'react-scroll-parallax';
import GoogleAnalyticsProvider from './providers/GoogleAnalyticsProvider';

function App() {
  const [isPreLoaderLoaded, setPreLoaderStatus] = useState(false)
  useEffect(() => {
    const isLoaded = setTimeout(() => {
      setPreLoaderStatus(true);
    }, 5000);
    return () => clearTimeout(isLoaded);
  }, []);
  return (
    <>
      <ParallaxProvider>
        <GoogleAnalyticsProvider>
          <Router>
            <AnimatedCursor
              innerSize={10}
              outerSize={30}
              color='250, 114, 115'
              outerAlpha={0.2}
              innerScale={0.5}
              outerScale={2}
              trailingSpeed={8}
              clickables={[
                'a',
                'input[type="text"]',
                'input[type="email"]',
                'input[type="number"]',
                'input[type="submit"]',
                'input[type="image"]',
                'label[for]',
                'select',
                'textarea',
                'button',
                '.link'
              ]}
            />
            <Header isPreLoaderLoaded={isPreLoaderLoaded} />
            <ScrollToTop />
            <Routes isPreLoaderLoaded={isPreLoaderLoaded} />
            <Footer isPreLoaderLoaded={isPreLoaderLoaded} />
            <Cookie isPreLoaderLoaded={isPreLoaderLoaded} />
          </Router>
        </GoogleAnalyticsProvider>
      </ParallaxProvider>
    </>
  );
}
export default App;
