import React, { useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowRight } from '../assets/svgs/arrowRightBlack.svg'
import { motion } from "framer-motion"
export default function RequestDemoButton(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const { parentPage } = props;

    const handleSubmit = (events) => {
        // console.log("Button clicked request demo: ", events);
        navigate('/contact', { state: {additionalProp: parentPage } });
    }

    // console.log("Props from contact page in Thank you page: ", additionalProp);
    // useEffect(() => {
    //     if (location.state?.from !== 'contact-page') {
    //         navigate('/page-not-found');
    //       }
    // }, []);

    return (
        <motion.div className='request-demo-page' initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: parentPage == 'About' ? 0 : 80 }}
        transition={{ duration: 0.8 }}>
            <div className=' pure-g'>
                    
                <div className='button-parent pure-u-xl-10-24'>
                    <button className='button-container' type="submit" onClick={handleSubmit}>
                        Request a demo
                    </button>
                    <ArrowRight />
                </div>

            </div>
    </motion.div>
    )
}
