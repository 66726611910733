import { motion } from "framer-motion";
import PathRight from "./PathRight";
import { assets } from "../../assets/aws-assets";
import ScreenDetect from "../../hooks/ScreenDetect";
import RequestDemoButton from "../../views/requestDemoButton";
function HereSection() {
  const { isMobile, isTab, isLaptop, is4k } = ScreenDetect();
  return (
    <>
      <div className="container container-home homepage-hero-section pure-g">
        <div
          className={`${isMobile ? " pure-u-24-24" : ""}${
            isTab ? " pure-u-12-24" : ""
          }${isLaptop ? " pure-u-16-24" : ""}${
            !isMobile && !isTab && !isLaptop ? " pure-u-14-24" : ""
          }  left`}>
          <motion.div
            className="highlight pure-g"
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 80 }}
            transition={{ duration: 0.8 }}>
            <h2
              className={`${isMobile ? " pure-u-24-24" : ""}${
                isTab ? " pure-u-24-24" : ""
              }${isLaptop ? " pure-u-19-24" : ""}${
                !isMobile && !isTab && !isLaptop && !is4k ? " pure-u-20-24" : ""
              }${is4k ? " pure-u-16-24" : ""}`}>
              <span>Driving global transportation to net-zero</span>
              <br />
              Turn your emission targets into action
            </h2>
            {/* <RequestDemoButton /> */}
          </motion.div>
          {!(isMobile) && <motion.div
            className="heresection-request-demo"
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}>
              <RequestDemoButton parentPage='Landing page' />
            </motion.div>}
          
          {isMobile && (
            <motion.div
              initial={{ opacity: 0, y: -80 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="gif-container">
              <img
                src={assets.mobileCarGIF}
                className="gif"
                alt="car-moving-aniamtion"
                loading="lazy"></img>
            </motion.div>
          )}
          <div
            className={`brand ${isMobile ? " pure-u-23-24" : ""}${
              isTab ? " pure-u-24-24" : ""
            }${isLaptop ? " pure-u-14-24" : ""}${
              !isMobile && !isTab && !isLaptop ? " pure-u-14-24" : ""
            }`}>
            <motion.div
              className={`content`}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 10 }}
              transition={{ duration: 0.5, delay: 0.7 }}>
              <h1 className="pure-u-8-24">Clearly</h1>
              <p
                className={`${isMobile ? " pure-u-24-24" : ""}${
                  isTab ? " pure-u-20-24" : ""
                }${isLaptop ? " pure-u-24-24" : ""}${
                  !isMobile && !isTab && !isLaptop ? " pure-u-24-24" : ""
                }`}>
                <span>Understand your emissions at the trip-level</span> <br />
                Stay compliant, reduce emissions, save money
              </p>
            </motion.div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: -80 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className={`${isMobile ? " pure-u-24-24" : ""}${
            isTab ? " pure-u-11-24" : ""
          }${isLaptop ? " pure-u-8-24" : ""}${
            !isMobile && !isTab && !isLaptop ? " pure-u-6-24" : ""
          } paths right`}>
          {!isMobile && <PathRight />}
        </motion.div>
        {
          (isMobile) && <motion.div
          className="heresection-request-demo"
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: -80 }}
          transition={{ duration: 0.8 }}>
            <RequestDemoButton parentPage='Landing page' />
          </motion.div>
        }
      </div>
    </>
  );
}
export default HereSection;
