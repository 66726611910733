import React from 'react'
import Showcase from '../components/Showcase'
import HereSection from '../components/Hompage/HereSection';
import TabNavigator from '../components/Hompage/TabNavigator';
import Highlighter from '../components/Hompage/Highlighter';
import PolicyFramework from '../components/Hompage/PolicyFramework';
import Usages from '../components/Hompage/Usages';
import QuickNavigator from '../components/Hompage/QuickNavigator';
import HomeLayered from '../components/Hompage/Layered';
import HomeLayeredSmall from '../components/Hompage/LayeredSmall';
import Preloader from '../components/Preloader';
import ScreenDetect from '../hooks/ScreenDetect';
function HomePage(props) {
  const isPreLoaderLoaded = props.isPreLoaderLoaded
  const { isMobile, isTab } = ScreenDetect();
  return (
    isPreLoaderLoaded ?
      <>
        <HereSection />
        <TabNavigator />
        <Highlighter />
        {(!isMobile && !isTab) &&
          <HomeLayered />
        }
        {(isMobile || isTab) &&
          <HomeLayeredSmall />
        }
        <PolicyFramework />
        <Usages />
        <QuickNavigator />
        <div className='showcases'>
          <Showcase className="investors & partners" backgroundColor="#7C7C7C" isMobile={isMobile} />
          <Showcase className="awards" backgroundColor="#434343" isMobile={isMobile} />
          <Showcase className="standards" backgroundColor="#242424" isMobile={isMobile} />
        </div>
      </>
      :
      <Preloader />
  )
}
export default HomePage;