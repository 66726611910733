import { Route, Routes as Routing } from 'react-router-dom';
import HomePage from './views/Home';
import AboutPage from './views/About';
import BlogPage from './views/Blog';
import BlogPost from './views/BlogPost';
import ContactPage from './views/Contact';
import TeamPage from './views/Team';
import ErrorPage from './views/404';
import DefaultPage from './views/Default';
import TabsPage from './views/Tabs';
import ThankYou from './views/ThankYou';
import { Navigate } from 'react-router-dom';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import Loader from './components/Loader';

const FeatureSwitched = ({ feature, children }) => {
    const flagsmith = useFlagsmith();
    const flags = useFlags([feature]);

    if (flagsmith.isLoading) {
        return (
            <div className="tw-h-40 tw-w-full tw-flex tw-items-center tw-justify-center">
                <Loader />
            </div>
        )
    }

    if (!flags[feature].enabled) {
        return <Navigate to={{ pathname: "/page-not-found" }} />
    }

    return <>{ children }</>
}
 
const Routes = ({ isPreLoaderLoaded }) => {
    return (
        <Routing>
            <Route end path="/" element={<HomePage isPreLoaderLoaded={isPreLoaderLoaded} />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/blog" element={<FeatureSwitched feature="blog"><BlogPage /></FeatureSwitched> }/>
            <Route path="/blog/:slug" element={<FeatureSwitched feature="blog"><BlogPost /></FeatureSwitched> } />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/identify" element={<TabsPage pageIs="identify" />} />
            <Route path="/uncover" element={<TabsPage pageIs="uncover" />} />
            <Route path="/finance" element={<TabsPage pageIs="finance" />} />
            <Route path="/terms-and-conditions" element={<DefaultPage pageName="terms-and-conditions" />} />
            <Route path="/privacy-policy" element={<DefaultPage pageName="privacy-policy" />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/page-not-found" element={<ErrorPage />} />
            <Route path="/*" element={<Navigate to={{ pathname: "/page-not-found" }} />} />
        </Routing>
    )
}

export default Routes;