import React, { useState } from 'react'
import Cookies from 'universal-cookie';
import { ReactComponent as ArrowRight } from '../assets/svgs/arrowRight.svg';
import { ReactComponent as XMark } from '../assets/svgs/xMark.svg';
import { motion } from "framer-motion"
import { useLocation } from 'react-router-dom';
import ga from '../helpers/ga';
function Cookie(props) {
    const isPreLoaderLoaded = props.isPreLoaderLoaded
    const [, setCookie] = useState(false);
    const cookies = new Cookies();
    const location = useLocation();
    const currentDate = new Date();
    const requestAgainOn = new Date(currentDate)
    requestAgainOn.setDate(currentDate.getDate() + 30);
    if (requestAgainOn.getMonth() !== currentDate.getMonth()) {
      requestAgainOn.setMonth(currentDate.getMonth() + 1);
    }

    const acceptCookie = () => {
        cookies.set('cookieAcceptance', "accepted", { path: "/" });
        setCookie(true);
        ga.allowTracking();
    }
    const declineCookie = () => {
        cookies.set('cookieAcceptance', "denied", { path: "/", expires: requestAgainOn });
        setCookie(true);
    }
    return (
      (isPreLoaderLoaded || location.pathname !== "/") && (
        <>
          {!cookies.get("cookieAcceptance") && (
            <motion.div
              className="container cookie pure-g"
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="text pure-u-lg-10-24">
                <p>
                  This website utilises cookies. By clicking on the ‘Accept
                  Cookies’ button, you are agreeing that Google Analytics may
                  store cookies and reveal information in accordance with our{" "}
                  <a href="https://clearly.earth/privacy-policy">
                    <u>Privacy Policy.</u>
                  </a>
                </p>
              </div>
              <div className="buttons pure-u-lg-14-24">
                <fieldset className="accept-cookie" onClick={acceptCookie}>
                  <button type="submit">Accept Cookies</button>
                  <ArrowRight />
                </fieldset>
                <fieldset className="decline-cookie" onClick={declineCookie}>
                  <button type="submit">Decline Cookies</button>
                  <XMark />
                </fieldset>
              </div>
            </motion.div>
          )}
        </>
      )
    );
}
export default Cookie