import React from 'react';
import ReactDOM from 'react-dom/client';
import "purecss/build/pure.css"
import App from './App';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { storyblokInit, apiPlugin } from "@storyblok/react";
import BlogHome from "./components/Storyblok/BlogHome";
import BlogPost from './components/Storyblok/BlogPost';
import BlogAuthor from './components/Storyblok/BlogAuthor';
import YouTubeVideo from './components/Storyblok/YouTubeVideo';
import VimeoVideo from './components/Storyblok/VimeoVideo';
import BlogWrappedImage from './components/Storyblok/BlogWrappedImage';
 
storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components: {
    blog_home: BlogHome,
    blog_post: BlogPost,
    blog_author: BlogAuthor,
    youtube_video: YouTubeVideo,
    vimeo_video: VimeoVideo,
    blog_wrappedimage: BlogWrappedImage
  },
  apiOptions: {
    region: "eu"
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FlagsmithProvider options={{ environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_KEY }} flagsmith={flagsmith}>
      <App />
    </FlagsmithProvider>
  </React.StrictMode>
);

