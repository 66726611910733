import React, { useEffect, useState } from "react";
import MemberCard from "../components/MemberCard";
// import ThreeImgBack from '../components/ThreeImgBack';
import AnimatedDiv from "../components/AnimatedDiv";
import { motion } from "framer-motion";
import ScreenDetect from "../hooks/ScreenDetect";
import RequestDemoButton from "./requestDemoButton";
export default function TeamPage() {
  const { isMobile, isTab, isLaptop } = ScreenDetect();
  const [teamMember, setTeamMember] = useState([]);
  useEffect(() => {
    fetch("./datasets/members.json")
      .then((response) => response.json())
      .then((data) => {
        setTeamMember(data.members);
        // isMobile
        //   ? setTeamMember(data.members)
        //   : setTeamMember(data.members.reverse());
      });
  }, []);
  return (
    <>
      <motion.div
        className="team-page"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        {/* <ThreeImgBack /> */}
        <div className="container main-content pure-g">
          <div className="title-row pure-u-24-24">
            <AnimatedDiv>
              <h1>
                <span>Meet</span> the team
              </h1>
            </AnimatedDiv>
          </div>
          <div className="content-row pure-u-xxl-5-24 pure-u-xl-9-24 pure-u-md-14-24">
            <AnimatedDiv>
              <p>
                We’re a growing multidisciplinary team of data scientists,
                engineers and industry experts working to develop innovative
                technology that helps organisations achieve net-zero
                decarbonisation targets.
              </p>
            </AnimatedDiv>
            <div className="title">
              <AnimatedDiv>
                <h3>Founding team</h3>
              </AnimatedDiv>
            </div>
          </div>
        </div>
        <div
          className={
            isMobile
              ? "container team-list pure-g teamMobile"
              : "container team-list pure-g"
          }>
          <div
            className={
              isMobile
                ? "left pure-u-xxl-5-24 pure-u-xl-8-24 pure-u-sm-24-24 teamTextMobile"
                : "left pure-u-xxl-5-24 pure-u-xl-8-24 pure-u-sm-24-24"
            }>
            <div className="content">
              <AnimatedDiv>
                <p>
                  The founding team consists of Danielle Walsh and Dr. Pedro
                  Baiz. Walsh is a Cambridge graduate who represented HSBC on
                  its $17bn tech spend: she built Future Cities & New
                  Industries, advising public/private sectors globally, and was
                  a governing board member of REEEP. Baiz is an Imperial
                  graduate who served as a data lead at HSBC and Amey, and is
                  the ESG Fintech Lead at International Standards (ISO TC 322
                  TAG1).
                </p>
                <p>
                  Whilst seeking to solve the funding challenge to decarbonise
                  the transport industry, Walsh came up with the idea of fusing
                  data across the supply chain to support shared financing
                  instruments. Further research revealed that the same data
                  insights could solve the growing demands of navigating route
                  emissions restrictions, and managing emissions reductions.
                </p>
                <p>
                  Steering that level of data is complex, and is a field that
                  Baiz had devoted his career to solving. They decided to
                  combine their expertise and build a data platform that enables
                  stakeholders across the transportation industry to work
                  together to reach net zero emissions.
                </p>
                <p>
                  That's how Clearly was born - a company committed to
                  harnessing the power of data fusion to aid a collective
                  transition to a cleaner, more sustainable future.
                </p>
              </AnimatedDiv>
            </div>
          </div>

          <div className="right pure-u-xl-14-24">
            <div className="founder-team pure-g">
              {teamMember.map(
                (member) =>
                  member.category === "founder" && (
                    <div
                      className={`${isMobile ? " pure-u-24-24" : ""}${
                        isTab ? " pure-u-11-24" : ""
                      }${isLaptop ? " pure-u-10-24" : ""}${
                        !isMobile && !isTab && !isLaptop
                          ? " pure-u-md-10-24"
                          : ""
                      } mt-lg-10`}
                      key={member.name}>
                      {!isMobile ? (
                        <AnimatedDiv>
                          <MemberCard
                            memberName={member.name}
                            memberPosition={member.position}
                            memberImg={member.image}
                            memberSocial={member.socials}
                          />
                        </AnimatedDiv>
                      ) : (
                        <MemberCard
                          memberName={member.name}
                          memberPosition={member.position}
                          memberImg={member.image}
                          memberSocial={member.socials}
                        />
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
        <motion.div
        className="team-page-request-demo"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: -150 }}
        transition={{ duration: 0.5 }}>

          <RequestDemoButton parentPage='Team' />

        </motion.div>
        
      </motion.div>
    </>
  );
}
